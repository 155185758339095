import { Box, BoxProps, Heading, Text } from "~/ui/components";

interface Props
  extends BoxProps,
    Pick<React.ComponentProps<typeof Heading>, "level"> {
  title: string;
  intro?: React.ReactNode;
}

const FormIntro = ({ title, intro, ...boxProps }: Props) => {
  return (
    <Box space="small" textCenter {...boxProps}>
      <Heading level="h1">{title}</Heading>
      {intro ? <Text variant="light">{intro}</Text> : null}
    </Box>
  );
};

export { FormIntro };

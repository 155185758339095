import Head from "next/head";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import appConfig from "~/config/app";
import { Box, Card, Heading, Text } from "~/ui/components";
import { FormIntro } from "~/ui/components/form-intro";
import Logo from "~/ui/components/logo";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("404.title")}</title>
      </Head>
      <Wrapper>
        <Container>
          <Box>
            <Logo href="/" />
            <Box>
              <Card className="not-found-content__container">
                <FormIntro
                  title={t("404.heading")}
                  intro={t("404.introductory")}
                />
                <Text>
                  {t("404.message[0]")} &nbsp;
                  <a href={appConfig.publicBaseUrl}>{t("404.message[1]")}</a>
                </Text>
              </Card>
            </Box>
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 420px;
  margin-bottom: 20px;
  text-align: center;

  & .not-found-content__container {
  }

  @media (max-width: 480px) {
    width: 100%;

    & .not-found-content__container {
    }
  }
`;

export { NotFound };

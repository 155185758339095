import Image from "next/image";
import styled from "styled-components";

import planturaLogo from "~/assets/plantura-logo-full.svg";

type Props = {
  href?: string;
};

const Logo = ({ href }: Props) => {
  const imgProps = {
    alt: "Plantura Logo",
    height: 46,
    src: planturaLogo,
  };

  return (
    <Wrapper>
      <Container>
        {href ? (
          <a href={href}>
            <Image {...imgProps} priority={true} />
          </a>
        ) : (
          <Image {...imgProps} priority={true} />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-inline: 1rem;
  padding-block: 0.75rem;

  background-color: var(--color-white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  flex: 1;
  max-height: 46px;
  max-width: 998px;
`;

export default Logo;
